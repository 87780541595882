var home = (function() {

	var preload = function () {
        $(window).on("load", function (e) {
            var body = $('body');

            $("#preload").fadeOut("slow");
            body.css('overflow', 'auto');
        });
    }

    // Burger Menu
    var burgerMenu = function() {

        $('body').on('click', '.js-fh5co-nav-toggle', function(event){

            event.preventDefault();

            if ( $('#navbar').is(':visible') ) {
                $(this).removeClass('active');
            } else {
                $(this).addClass('active');
            }



        });

    };

    // Window Scroll
    var windowScroll = function() {
        var lastScrollTop = 0;

        $(window).scroll(function(event){

            var header = $('#fh5co-header'),
                scrlTop = $(this).scrollTop();

            if ( scrlTop > 500 && scrlTop <= 2000 ) {
                header.addClass('navbar-fixed-top fh5co-animated slideInDown');
            } else if ( scrlTop <= 500) {
                if ( header.hasClass('navbar-fixed-top') ) {
                    header.addClass('navbar-fixed-top fh5co-animated slideOutUp');
                    setTimeout(function(){
                        header.removeClass('navbar-fixed-top fh5co-animated slideInDown slideOutUp');
                    }, 100 );
                }
            }

        });
    };

    var piChat = function() {
        var chat_activate = $('#chat_activate'),
            message_active = $('.social-media-pub.activate'),
            join_message = $('#join_message'),
            link_effet = $('.link-effet');

        join_message.mouseenter(function() {
            console.log('......');
            chat_activate.addClass('activate');
            join_message.removeClass('open-chat');
            join_message.addClass('close-chat');
            link_effet.removeClass('button-slide-out');
            link_effet.addClass('button-slide');
        }).mouseleave(function() {
            console.log('no dejo su mensaje :(');
        });

        chat_activate.delegate( ".open-chat", "click", function(e) {
            e.preventDefault();
            chat_activate.addClass('activate');
            join_message.removeClass('open-chat');
            join_message.addClass('close-chat');
            link_effet.removeClass('button-slide-out');
            link_effet.addClass('button-slide');
        });

        chat_activate.delegate( ".close-chat", "click", function(e) {
            e.preventDefault();
            console.log('chaufi');
            join_message.addClass('open-chat');
            chat_activate.removeClass('activate');
            link_effet.addClass('button-slide-out');
            link_effet.removeClass('button-slide');
            $(this).removeClass('close-chat');
        });
    };

    var slider = function() {
        $('#banner').slick({
            autoplay: true,
            autoplaySpeed: 5000,
            dots: false,
            arrows: false,
            responsive: [
                {
                  breakpoint: 480,
                      settings: {
                        slidesToShow: 1,
                        //arrows: false,
                        //dots: true,
                      }
                }
          ]
        });
    }

    var gallerySrv = function() {
        $('#gallery-srv').slick({
            //centerMode: true,
            //centerPadding: '60px',
            slidesToShow: 4,
            autoplay: true,
            autoplaySpeed: 5000,
            dots: false,
            responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    arrows: false,
                    dots: true,
                    //centerMode: true,
                    //centerPadding: '40px',
                    slidesToShow: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 1
                  }
                }
              ]
        });
    }

    var sliderMobile = function () {
        var banner =$('.banner-store'),
            img_tienda =$('.img-tienda'),
            img_pedidos =$('.img-pedidos'),
            window_width = $(window).width();

            if (window_width <= 414) {
                var new_src = banner.find(img_tienda).data('img');
                var new_src_pedidos = banner.find(img_pedidos).data('img');
                img_tienda.attr('src', '/images/slider/store/'+new_src+'.jpg');
                img_pedidos.attr('src', '/images/slider/store/'+new_src_pedidos+'.jpg');
            }
    }


	// RENDER FRONT
	return {
		init: function() {
			preload();
            slider();
            sliderMobile();
            burgerMenu();
            windowScroll();
            piChat();
            gallerySrv();
		}

	}


})();

$(document).ready(function() {
	home.init()
});